<template>
  <el-main>
    <el-form class="el-form-search" label-width="100px">
      <el-form-item label="评论关键字：">
        <el-input size="small" placeholder="请输入评价内容关键字" v-model="content"></el-input>
      </el-form-item>
      <el-form-item label="用户昵称：">
        <el-input size="small" placeholder="请输入用户昵称" v-model="nickname"></el-input>
      </el-form-item>
      <el-form-item label="评价日期：">
        <el-date-picker v-model="timeVal" size="small" type="daterange" range-separator="~" start-placeholder="开始日期" end-placeholder="结束日期" @change="getTime"></el-date-picker>
      </el-form-item>
      <el-form-item label="订单编号：">
        <el-input size="small" placeholder="请输入订单编号" v-model="pay_sn"></el-input>
      </el-form-item>
      <el-form-item label="评分等级：">
        <el-select v-model="star_level" size="small" placeholder="请选择">
          <el-option v-for="item in levelSelect" :key="item.val" :label="item.label" :value="item.val"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="是否回复：">
        <el-select v-model="is_reply" size="small" placeholder="请选择">
          <el-option v-for="item in replySelect" :key="item.val" :label="item.label" :value="item.val"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label=" " label-width="25px">
        <el-button type="primary" size="small" @click="getList()">搜索</el-button>
        <el-button type="text" size="small" @click="cancelSearch">清空搜索条件</el-button>
      </el-form-item>
    </el-form>
    <div class="evaluate-list" :class="[!list.length ? 'center' : '']">
      <template v-if="!list.length">
        <No />
      </template>
      <div class="evaluate-item" v-for="(item, index) in list" :key="index">
        <div class="evaluate-left">
          <div class="header">
            <!-- <el-checkbox>全选</el-checkbox> -->
            <p class="name">评价人：{{ item.nickname }}</p>
            <p class="num">订单号：{{ item.pay_sn }}</p>
          </div>
          <div class="content">
            <el-image :src="item.goods_img"></el-image>
            <div class="goods-info">
              <p>{{ item.goods_title }}</p>
              <p>规格：红色s</p>
            </div>
          </div>
        </div>
        <div class="evaluate-right">
          <div class="header">
            <p>下单时间：{{ getDateformat(item.order_time) }}</p>
            <div class="rate">
              <span>评分：</span>
              <el-rate v-model="item.star_level" disabled></el-rate>
            </div>
          </div>
          <div class="content">
            <div class="user-evaluate">
              <div class="text">{{ item.content }}</div>
              <div class="img" v-show="item.pictures.length">
                <el-image :src="pictures" v-for="(pictures, indexs) in item.pictures" :preview-src-list="item.pictures" :key="indexs"></el-image>
              </div>
              <div class="footer">
                <div class="left">
                  <div>
                    <p>{{ getDateformat(item.comment_time) }}</p>
                    <p>{{ item.nickname }}</p>
                  </div>
                  <!-- <el-button v-if="item.is_reply == 2" type="primary" size="small" @click="handelReplied(index)">已回复</el-button> -->
                  <div v-if="item.is_reply == 2">商家回复：{{ item.reply }}</div>
                </div>
                <div class="right">
                  <el-button v-if="item.is_reply == 1" type="text" size="small" @click="handelReply(index)">回复</el-button>
                  <el-button v-if="item.status == 2" type="text" size="small" @click="showComment(index)">显示</el-button>
                  <el-button v-else type="text" size="small" @click="showComment(index)">隐藏</el-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Paging :total="total_number" :page="page" :pageNum="rows" @updatePageNum="updateData"></Paging>
    <el-dialog title="回复评价" :visible.sync="showReply" width="600px" :before-close="handleClose">
      <el-form>
        <el-form-item label="商品名称：">{{ replyGoodsName || '- -' }}</el-form-item>
        <el-form-item label="回复内容：" class="reply-con">
          <el-input type="textarea" rows="8" v-model="reply"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showReply = !1">取 消</el-button>
        <el-button type="primary" @click="confirmReply">确 定</el-button>
      </span>
    </el-dialog>
  </el-main>
</template>
<script>
import { getDateformat } from '../../util/getDate';
import Paging from '@/components/paging';
const industryName = '/Gateway'; //行业版本接口名称（用于更换重复接口）
export default {
  components: {
    Paging,
  },
  data() {
    return {
      showReply: !1,
      rows: 10,
      page: 1,
      total_number: 0,
      is_reply: -1,
      content: '',
      nickname: '',
      start_time: '',
      end_time: '',
      pay_sn: '',
      star_level: '',
      list: [],
      timeVal: '',
      reply: '', //回复内容
      reply_id: '',
      replyGoodsName: '',
      reply_list: [],
      replySelect: [
        {
          val: -1,
          label: '全部',
        },
        {
          val: 2,
          label: '是',
        },
        {
          val: 1,
          label: '否',
        },
      ],
      levelSelect: [
        {
          val: '',
          label: '全部',
        },
        {
          val: 1,
          label: '一级',
        },
        {
          val: 2,
          label: '二级',
        },
        {
          val: 3,
          label: '三级',
        },
        {
          val: 4,
          label: '四级',
        },
        {
          val: 5,
          label: '五级',
        },
      ],
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getDateformat(val) {
      return getDateformat(val);
    },
    updateData(val, status) {
      if (status == 0) {
        this.rows = val;
        this.getList();
      } else {
        this.page = val;
        this.getList();
      }
    },
    cancelSearch() {
      this.content = '';
      this.nickname = '';
      this.pay_sn = '';
      this.star_level = '';
      this.is_reply = -1;
      this.timeVal = '';
      this.start_time = '';
      this.end_time = '';
      this.getList();
    },
    handelReply(index) {
      this.reply = '';
      this.reply_id = this.list[index].id;
      this.replyGoodsName = this.list[index].goods_title;
      this.showReply = !0;
    },
    getTime(val) {
      this.start_time = val[0].getTime() / 1000;
      this.end_time = val[1].getTime() / 1000;
    },
    showComment(index) {
      let status = this.list[index].status;
      this.$axios
        .post(industryName+ this.$api.goods.setStatus, {
          id: this.list[index].id,
        })
        .then(res => {
          if (res.code == 0) {
            status == 1 ? (this.list[index].status = 2) : (this.list[index].status = 1);
            this.$message({
              message: '设置成功',
              type: 'success',
            });
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    handelReplied(index) {
      this.reply_id = this.list[index].id;
      this.showReplied = !0;
    },
    confirmReply() {
      if (!this.reply)
        return this.$message({
          message: '请输入回复内容',
          type: 'warning',
        });
      this.$axios
        .post(industryName + this.$api.goods.reply, {
          reply: this.reply,
          id: this.reply_id,
        })
        .then(res => {
          if (res.code == 0) {
            this.showReply = !1;
            this.getList();
            this.$message({
              message: '回复成功',
              type: 'success',
            });
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    getList() {
      let data = {
        content: this.content,
        nickname: this.nickname,
        pay_sn: this.pay_sn,
        star_level: this.star_level,
        page: this.page,
        rows: this.rows,
        start_time: this.start_time,
        end_time: this.end_time,
      };
      if (this.is_reply >= 1) data.is_reply = this.is_reply;
      this.$axios.post(industryName + this.$api.goods.commentList, data).then(res => {
        if (res.code == 0) {
          let list = res.result.list;
          this.total_number = res.result.total_number;
          this.list = list;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background: #fff;
}
.evaluate-list {
  border: 1px solid #dcdfe6;
  .evaluate-item {
    display: flex;
    font-size: 14px;
    color: #606266;
    border-bottom: 1px solid #dcdfe6;
    .evaluate-left {
      width: 550px;
      display: flex;
      flex-direction: column;
      .header {
        height: 40px;
        background: #f8f8fa;
        display: flex;
        align-items: center;
        padding-left: 20px;
        .name {
          margin-right: 30px;
          max-width: 170px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      .content {
        flex: 1;
        padding: 10px 0 10px 20px;
        display: flex;
        align-items: center;
        border-right: 1px solid #dcdfe6;
        .el-image {
          width: 60px;
          height: 60px;
          margin-right: 20px;
        }
        .goods-info {
          p:nth-child(1) {
            margin-bottom: 5px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            max-width: 240px;
          }
        }
      }
    }
    .evaluate-right {
      flex: 1;
      display: flex;
      flex-direction: column;
      .header {
        height: 40px;
        background: #f8f8fa;
        padding-left: 15px;
        display: flex;
        align-items: center;
        .rate {
          display: flex;
          align-items: center;
          margin-left: 100px;
        }
      }
      .content {
        padding: 10px 30px 10px 15px;
        flex: 1;
        .user-evaluate {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 100%;
          .img {
            height: 60px;
            display: flex;
            margin: 15px 0;
            .el-image {
              width: 60px;
              height: 100%;
              margin-right: 10px;
            }
          }
          .footer {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .left {
              display: flex;
              align-items: center;
              div {
                display: flex;
                margin-right: 70px;
              }
              p {
                margin-right: 15px;
              }
            }
          }
        }
      }
    }
  }
}
.reply-con {
  display: flex;
  /deep/ .el-form-item__content {
    flex: 1;
  }
}
.el-table {
  border-left: 1px solid #e8eef1;
  border-right: 1px solid #e8eef1;
}
.center {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
